<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--form-container">
        <form @submit.prevent="submit">
          <section-loading :show="loading" />
          <vue-dropzone
            v-show="false"
            ref="photoDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-sending="sending"
            @vdropzone-success="success"
            @vdropzone-complete="completed"
          />
          <v-btn
            type="button"
            color="primary"
            class="btn btn-primary--light btn-block"
            @click="triggerDropzone"
          >
            {{ $t('mediaFile.uploadNew') }}
          </v-btn>
          <div class="media--gallery row">
            <div
              class="col-3"
              v-for="media in mediaFiles"
              :key="`media-gallery-${media.uuid}`"
              @click="selectNewMedia(media)"
            >
              <img
                :class="{ selected: selectedMedia && selectedMedia.id === media.id }"
                :src="media.url"
              />
              <!--              <v-btn color="error" @click="removeMediaFile(media)">-->
              <!--                <v-icon v-text="'mdi-trash-can-outline'" />-->
              <!--              </v-btn>-->
              <base-delete-button
                :permissions="['DELETE']"
                :id="media.id"
                :showIcon="true"
                @deleteAction="removeMediaFile"
              ></base-delete-button>
            </div>
          </div>
          <pagination
            :push-state="false"
            :meta="meta"
            @changePage="changePage"
            :maxVisibleButtons="5"
            v-if="meta"
          />
          <div class="d-flex align-center justify-center mt-4">
            <v-btn
              color="primary"
              class="bottom--button"
              type="submit"
              :disabled="selectedMedia === null"
            >
              {{ $t('mediaFile.btn.choose') }}
            </v-btn>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import vue2Dropzone from 'nuxt-dropzone';
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import SectionLoading from '@/components/content-loading/section-loading';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  name: 'modal-media-file',
  mixins: [HelperMixin],
  components: { vueDropzone: vue2Dropzone, SectionLoading, Pagination },
  data() {
    return {
      modalName: 'modal-media-file',
      selectedMedia: null,
      selectedMediaId: null,
      commitString: null,
      dispatchString: null,
      page: 1,
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/media_files/upload`,
        thumbnailMethod: 'contain',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
        resizeWidth: 300,
        resizeHeight: 300,
        resizeQuality: 1,
      },
    };
  },
  computed: {
    ...mapState({
      mediaFiles: (state) => state.mediaFile.mediaFiles,
      meta: (state) => state.mediaFile.meta,
    }),
    loading: {
      get() {
        return this.$store.state.mediaFile.loading;
      },
      set(value) {
        this.$store.commit('mediaFile/SET_LOADING', value);
      },
    },
  },
  watch: {
    mediaFiles(val) {
      if (this.selectedMediaId !== null) {
        for (let media of val) {
          if (media.id === this.selectedMediaId) {
            this.selectNewMedia(media);
            break;
          }
        }
      }
    },
  },
  methods: {
    beforeOpen(event) {
      this.selectedMediaId = event.params.selectedMediaId;
      this.selectedMedia = null;
      this.page = 1;
      this.commitString = event.params.commitString;
      this.dispatchString = event.params.dispatchString;
      this.$store.dispatch('mediaFile/getMediaFiles');
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async submit() {
      if (this.commitString) {
        this.$store.commit(this.commitString, this.selectedMedia);
      }
      if (this.dispatchString) {
        await this.$store.dispatch(this.dispatchString);
      }
      this.close();
    },
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
      this.changePage(this.page);
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      this.loading = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    // eslint-disable-next-line no-unused-vars
    success(file, response) {
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      this.page = page;
      await this.$store.dispatch('mediaFile/getMediaFiles', query);
    },
    selectNewMedia(media) {
      this.selectedMedia = media;
      this.selectedMediaId = null;
    },
    async removeMediaFile(id) {
      console.log('DELETE MEDIA FILE ID: ', id);
      try {
        await this.$store.dispatch('mediaFile/delete', id);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('mediaFile.delete.successMsg'),
          'success',
        );
        await this.changePage(this.page);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.media--gallery {
  margin: 16px 0;
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    border: solid 4px transparent;
    &.selected {
      border: solid 4px #d02a2e;
    }
  }
  .delete--button {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: white;
    border: solid 1px #d02a2e;
    border-radius: 50%;
    padding: 2px;
  }
}
</style>
